body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Fira Code', sans-serif;
}

.app {
  display: flex;
}

.name {
  font-size: 32px;
}

@media only screen and (min-width: 768px) {
  .app {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .left-side {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right-side {
    order: 2;
  }
}

.left-side {
  background-color: #1a1a1a;
  color: #fff;
  width: 50%;
}

.description {
  font-size: 20px;
  text-align: center;
  margin-bottom: 5%;
  padding-left: 50px;
}

.skill-badges {
  padding-left: 50px;
  padding-top: 2.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 120px;
}

.skill-badge svg {
  font-size: 3em;
}

.skill-name {
  margin-top: 5px;
}

.right-side {
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  order: 1;
}

.profile-image {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin-top: 20vh;
  margin-bottom: 25px;
}

@media only screen and (min-width: 1920px) {
  .profile-image {
    border-radius: 50%;
    width: 350px;
    height: 350px;
    margin-top: 25vh;
    margin-bottom: 25px;
  }  
}

.social-links {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: 2.5%;
}

.social-links a {
  color: #fff;
  text-decoration: none;
  margin: 5px;
}

.social-links i {
  font-size: 60px;
  margin-right: 20px;
}

.social-links a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .app {
    flex-direction: column;
    height: auto;
    width: auto;
    overflow: visible;
  }

  .name {
    font-size: 22px;
  }

  .description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5%;
    padding-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .skill-badges {
    padding-left: 0px;
    padding-top: 2.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .left-side,
  .right-side {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .right-side {
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .skill-badge {
    width: calc(25% - 20px);
  }
}
